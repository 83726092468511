export enum RoutesEnum {
	LOGIN = '/login',
	GOOGLE_CALLBACK = '/login/callback',
	SIGNUP = '/signup',
	VERIFYEMAIL = '/verify-email',
	CONFIRM_EMAIL = '/confirm_email',
	RESETPASSWORD = '/reset_password',
	LOGOUT = '/logout',
	HOME = '/',
	SWIPE = '/swipe-file',
	TRACKER = '/tracker',
	BRAND = '/brand',
	DISCOVERY = '/discovery',
	NEWAD = 'new-ad',
	PLANS = '/plans',
	SETTINGS = '/settings',
	TAGS = '/tags',
	AD_PUBLIC_DETAILS = '/external/ads',
	TAG_PUBLIC_VIEW = '/external/tags',
	CHECKOUT = '/checkout',
	PAYMENTSTATUS = '/payment-status',
	SUBSCRIPTION_CONFIRMATION = '/subscription-confirmation',
	TOP_10_ADS = 'top-10-ads',
}
