import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type ToggleOption = 'recentlyScaledAds' | 'tailoredAds' | 'estimatedAdSpend';

const initialState = {
	recentlyScaledAds: false,
	tailoredAds: true,
	estimatedAdSpend: true,
};

const toggleOptionsSlice = createSlice({
	name: 'toggleOptions',
	initialState,
	reducers: {
		toggleOption: (state, action: PayloadAction<{ option: ToggleOption }>) => {
			const { option } = action.payload;
			state[option] = !state[option];
		},
	},
});

export const { toggleOption } = toggleOptionsSlice.actions;
export default toggleOptionsSlice.reducer;
