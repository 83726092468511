import { RoutesEnum } from 'shared/enums';
import { clearLocalStorage } from 'shared/helpers';

import * as Sentry from '@sentry/react';
import type { AxiosError, AxiosInstance } from 'axios';
import axios from 'axios';
import { tokenApiService, tokenService } from 'core/token/services';
import { HttpStatusCodeEnum } from '../enums';
import { appendToken } from './';

const isAuthRoute = (path: string) => {
	return path === RoutesEnum.LOGIN;
};

const interceptResponse = (httpClient: AxiosInstance): void => {
	httpClient.interceptors.response.use(
		(response) => response,
		(error: AxiosError) => {
			const { response, message, config } = error;
			const method = config?.method?.toUpperCase() || 'UNKNOWN';
			const url = config?.url || 'UNKNOWN_URL';

			if (response) {
				Sentry.captureException(error, {
					tags: {
						status: response.status,
						method: method,
					},
					extra: {
						url: url,
						method: method,
						status: response.status,
						data: response.data,
					},
				});
			} else {
				Sentry.captureException(error, {
					extra: {
						message: message,
					},
				});
			}

			if (!response) {
				console.error(
					message === 'Network Error'
						? 'Network error - server is probably down'
						: 'Unknown error',
					error
				);

				return Promise.reject(error);
			}
			if (isAuthRoute(window.location.pathname)) {
				return Promise.reject(error);
			}

			switch (response.status) {
				case HttpStatusCodeEnum.Unauthorized:
					{
						const userRefreshToken = tokenService.refreshToken;
						const userString = window.localStorage.getItem('user');
						const user = userString ? JSON.parse(userString) : null;
						if (!userRefreshToken) {
							clearLocalStorage();
							window.location.href = RoutesEnum.LOGIN;
						} else {
							return tokenApiService
								.refreshToken(userRefreshToken, user?.id)
								.then((response: any) => {
									tokenService.token = response.token;
									tokenService.refreshToken = response.refreshToken;

									if (user) {
										const updatedUser = {
											...user,
											access_token: response.token,
											refresh_token: response.refreshToken,
										};
										window.localStorage.setItem(
											'user',
											JSON.stringify(updatedUser)
										);

										const refreshEvent = new CustomEvent('refreshToken', {
											detail: updatedUser,
										});
										window.dispatchEvent(refreshEvent);
									}

									appendToken(config!, response.token);
									return axios.request(config!);
								})
								.catch((error) => {
									console.error('Refresh token error', error);

									clearLocalStorage();
									window.location.href = RoutesEnum.LOGIN;
								});
						}
					}
					break;
				case HttpStatusCodeEnum.PaymentRequired:
					{
						window.location.href = RoutesEnum.CHECKOUT;
					}
					break;
				default:
					return Promise.reject(error);
			}
		}
	);
};

export default interceptResponse;
