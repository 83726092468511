import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'app/store/store';
import { setAnchorElId } from 'features/modal/modalManagementSlice';
import { setSearchConducted, setSearchResults, setSearchTerm } from 'features/search/searchSlice';
import { toggleOption } from 'features/toggleOptions/toggleOptionsSlice';
import { setBreakpointCols, setZoom } from 'features/zoom/gridZoomSlice';

interface ToggleOptions {
	tailoredAds?: boolean;
	recentlyScaledAds?: boolean;

	estimatedAdSpend?: boolean;
}

export const useSearchHook = () => {
	const dispatch = useDispatch();

	const handleSearch = useCallback(
		(searchValue: string) => {
			const trimmedSearchValue = searchValue.trim();
			dispatch(setSearchTerm(trimmedSearchValue));
			dispatch(setSearchConducted(trimmedSearchValue.length > 0));
		},
		[dispatch]
	);

	const handleResetSearch = useCallback(() => {
		dispatch(setSearchTerm(''));
		dispatch(setSearchConducted(false));
		dispatch(setSearchResults([]));
	}, [dispatch]);

	return { handleSearch, handleResetSearch };
};

export const useToggleOptionsHook = () => {
	const dispatch = useDispatch();
	const toggleOptions = useSelector((state: RootState) => state.toggleOptions) as ToggleOptions;

	const handleToggleOption = (option: keyof ToggleOptions) => {
		dispatch(toggleOption({ option }));
	};

	return { toggleOptions, toggleOption: handleToggleOption };
};

export const useModalManagementHook = () => {
	const dispatch = useDispatch();
	const anchorElId = useSelector((state: RootState) => state.modalManagement.anchorElId);
	const open = Boolean(anchorElId);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		dispatch(setAnchorElId(event.currentTarget.id));
	};

	const handleClose = () => {
		dispatch(setAnchorElId(null));
	};

	return { anchorElId, open, handleClick, handleClose };
};

export const useGridZoomHook = () => {
	const dispatch = useDispatch();

	const zoom = useSelector((state: RootState) => state.gridZoom.zoom);
	const breakpointCols = useSelector((state: RootState) => state.gridZoom.breakpointCols);
	const calculateColumns = (zoomLevel: number): { [key: string]: number } => {
		const baseColumns: { [key: string]: number } = {
			'default': 5,
			'2000': 4,
			'1600': 3,
			'1200': 2,
			'1000': 1,
		};

		let adjustment = 0;

		if (zoomLevel < 100) {
			adjustment = 1;
		} else if (zoomLevel === 110) {
			adjustment = -1;
		} else if (zoomLevel > 110) {
			adjustment = -2;
		}

		const adjustedColumns = Object.keys(baseColumns).reduce(
			(acc: { [key: string]: number }, key) => {
				const baseValue = baseColumns[key];
				const newColumnCount = Math.max(baseValue + adjustment, 1);
				acc[key] = newColumnCount;
				return acc;
			},
			{} as { [key: string]: number }
		);

		return adjustedColumns;
	};

	const handleZoomChange = (newZoom: number) => {
		dispatch(setZoom(newZoom));
		const newBreakpointCols = calculateColumns(newZoom);
		dispatch(setBreakpointCols(newBreakpointCols));
	};

	return { zoom, breakpointCols, handleZoomChange };
};
