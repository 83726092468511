import { SvgIcon, SvgIconProps } from '@mui/material';

export const ChevronLeftDoubleIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 21 20" fill="none" {...props}>
			<path
				d="M15.5 14.1667L11.3333 10L15.5 5.83334M9.66667 14.1667L5.5 10L9.66667 5.83334"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
};
