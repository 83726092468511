import { FiltersProps } from 'shared/context/FilterContext';
import { calculateReachFromAdSpend } from 'shared/helpers';
import { Ad, Ads, SaveAd } from 'shared/models/ads';

import { apiService } from 'core/api/services';

export class AdsApiService {
	async getAds(
		userId: number | undefined,
		page: number,
		sort: string = '{"reach.eu_total_reach":-1}',
		filters: FiltersProps[],
		search: string = '',
		brandId: string | undefined,
		recentlyScaledAds?: boolean,
		topAds?: boolean,
		tailoredAds?: boolean
	): Promise<Ads> {
		const limit = 30;
		const actualPage = Math.max(page, 1);
		const offset = (actualPage - 1) * limit;
		let url = `api/client/ads?offset=${offset}&limit=${limit}&sort=${sort}`;

		if (search) {
			url += `&search=${encodeURIComponent(search)}`;
		}

		if (userId) {
			url += `&user=${userId}`;
		}

		if (brandId) {
			url += `&brand_id=${brandId}`;
		}

		if (topAds) {
			url += `&topScaling=true`;
		}

		if (tailoredAds) {
			url += `&tailor=true`;
		}

		if (recentlyScaledAds) {
			url += `&topRecentlyScaled=true`;
		}

		const tagsFilter = filters.find((filter) => filter.filterGroupName === 'tags');
		const tagsQueryParam = tagsFilter ? `${tagsFilter.filterNames.join(',')}` : undefined;
		return apiService.responseHandler(
			await apiService.get<Ads>(url, {
				params: {
					brand_name: filters
						.find((filter) => filter.filterGroupName === 'brand')
						?.filterNames.join(','),
					tag: tagsQueryParam,
					format: filters
						.find((filter) => filter.filterGroupName === 'format')
						?.filterNames.map((filter) => filter),
					status: filters
						.find((filter) => filter.filterGroupName === 'status')
						?.filterNames.map((filter) => filter),
					platform: filters
						.find((filter) => filter.filterGroupName === 'platform')
						?.filterNames.join(','),
					languages: filters
						.find((filter) => filter.filterGroupName === 'language')
						?.filterNames.join(','),
					cta_button: filters
						.find((filter) => filter.filterGroupName === 'cta_button')
						?.filterNames.join(','),
					sourced_by: filters
						.find((filter) => filter.filterGroupName === 'ad_type')
						?.filterNames.join(','),
					campaign_start_date: filters
						.find((filter) => filter.filterGroupName === 'date')
						?.filterNames.filter((item) => item.startsWith('Start date-'))
						.map((item) => item.substring(item.indexOf('-') + 1)),
					campaign_end_date: filters
						.find((filter) => filter.filterGroupName === 'date')
						?.filterNames.filter((item) => item.startsWith('End date-'))
						.map((item) => item.substring(item.indexOf('-') + 1)),
					min_reach: calculateReachFromAdSpend(
						filters
							.find((filter) => filter.filterGroupName === 'ad_spend')
							?.filterNames.map((filter) => Number(filter))
							.reduce((a, b) => (a <= b ? a : b), Number.MAX_VALUE),
						'facebook'
					),
					max_reach: calculateReachFromAdSpend(
						filters
							.find((filter) => filter.filterGroupName === 'ad_spend')
							?.filterNames.map((filter) => Number(filter))
							.reduce((a, b) => (a > b ? a : b), Number.MIN_VALUE),
						'facebook'
					),
				},
			})
		);
	}

	async getUserAds(
		userId: number | undefined,
		page: number,
		sort: string = '{"reach.eu_total_reach":-1}',
		filters: FiltersProps[],
		tagsQueryString: string = '',
		search: string = ''
	): Promise<Ads> {
		const limit = 30;
		const actualPage = Math.max(page, 1);
		const offset = (actualPage - 1) * limit;
		let queryParams = `offset=${offset}&limit=${limit}&sort=${sort}${tagsQueryString}`;

		if (search) {
			queryParams += `&search=${encodeURIComponent(search)}`;
		}

		const tagsFilter = filters.find((filter) => filter.filterGroupName === 'tags');
		const tagsQueryParam = tagsFilter ? `${tagsFilter.filterNames.join(',')}` : undefined;

		return apiService.responseHandler(
			await apiService.get<Ads>(`api/client/user/${userId}/ads?${queryParams}`, {
				params: {
					brand_name: filters
						.find((filter) => filter.filterGroupName === 'brand')
						?.filterNames.join(','),
					tag: tagsQueryParam,
					format: filters
						.find((filter) => filter.filterGroupName === 'format')
						?.filterNames.map((filter) => filter),
					status: filters
						.find((filter) => filter.filterGroupName === 'status')
						?.filterNames.map((filter) => filter),
					platform: filters
						.find((filter) => filter.filterGroupName === 'platform')
						?.filterNames.join(','),
					languages: filters
						.find((filter) => filter.filterGroupName === 'language')
						?.filterNames.join(','),
					cta_button: filters
						.find((filter) => filter.filterGroupName === 'cta_button')
						?.filterNames.map((filter) => filter),
					sourced_by: filters
						.find((filter) => filter.filterGroupName === 'ad_type')
						?.filterNames.map((filter) => filter),
					campaign_start_date: filters
						.find((filter) => filter.filterGroupName === 'date')
						?.filterNames.filter((item) => item.startsWith('Start date-'))
						.map((item) => item.substring(item.indexOf('-') + 1)),
					campaign_end_date: filters
						.find((filter) => filter.filterGroupName === 'date')
						?.filterNames.filter((item) => item.startsWith('End date-'))
						.map((item) => item.substring(item.indexOf('-') + 1)),
					min_reach: calculateReachFromAdSpend(
						filters
							.find((filter) => filter.filterGroupName === 'ad_spend')
							?.filterNames.map((filter) => Number(filter))
							.reduce((a, b) => (a <= b ? a : b), Number.MAX_VALUE),
						'facebook'
					),
					max_reach: calculateReachFromAdSpend(
						filters
							.find((filter) => filter.filterGroupName === 'ad_spend')
							?.filterNames.map((filter) => Number(filter))
							.reduce((a, b) => (a > b ? a : b), Number.MIN_VALUE),
						'facebook'
					),
				},
			})
		);
	}

	async getAd(id: string, userId: number | undefined): Promise<Ad> {
		let url = `api/client/ads/${id}`;

		if (userId) {
			url = `api/client/ads/${id}?user=${userId}`;
		}
		return apiService.responseHandler(await apiService.get<Ad>(url));
	}

	async getUserAd(id: string, userId: number | undefined): Promise<Ad> {
		return apiService.responseHandler(
			await apiService.get<Ad>(`api/client/user/${userId}/ads/${id}`)
		);
	}

	async getPublicTagAd(id: string, token: string): Promise<Ad> {
		return apiService.responseHandler(
			await apiService.get<Ad>(`api/public/tag/ads/${id}`, { params: { token } })
		);
	}

	async getPublicAd(token: string | undefined): Promise<Ad> {
		return apiService.responseHandler(
			await apiService.get<Ad>('api/public/ads', { params: { token } })
		);
	}

	async saveAd(userId: number, adId: string, tags?: number[]): Promise<SaveAd> {
		return apiService.responseHandler(
			await apiService.post<SaveAd>(`api/client/user/${userId}/ads`, {
				user_id: userId,
				ad_id: adId,
				tagData: tags ? tags : [],
			})
		);
	}

	async updateAdTags(userId: number, adId: string, tags?: number[]): Promise<SaveAd> {
		return apiService.responseHandler(
			await apiService.patch<SaveAd>(`api/client/user/${userId}/ads/${adId}`, {
				tagData: tags ? tags : [],
			})
		);
	}

	async deleteAd(userId: number, adId: string): Promise<unknown> {
		return apiService.responseHandler(
			await apiService.delete<unknown>(`api/client/user/${userId}/ads/${adId}`)
		);
	}

	async postAd(
		adFormat: string | null,
		platform: string,
		sourced_by: string,
		status: string,
		tags?: number[]
	): Promise<SaveAd> {
		return apiService.responseHandler(
			await apiService.post<SaveAd>(`api/client/ads`, {
				format: adFormat,
				tagData: tags ? tags : [],
				status: status,
				platform: platform,
				sourced_by: sourced_by,
			})
		);
	}

	async getUploadUrl(adId: string, fileExtension: string): Promise<{ uploadUrl: string }> {
		const body = { file_extension: fileExtension };
		return apiService.responseHandler(
			await apiService.post<{ uploadUrl: string }>(`/api/client/ads/${adId}/media`, body)
		);
	}

	async getUserAdsCount(userId: number): Promise<number> {
		return apiService.responseHandler(
			await apiService.get<number>(`/api/client/user/${userId}/ads/count`)
		);
	}

	async getAdsCount(): Promise<number> {
		return apiService.responseHandler(await apiService.get<number>(`/api/client/ads/count`));
	}

	async getAllAdFilters(): Promise<{ brands: string[]; language: string[] }> {
		return apiService.responseHandler(
			await apiService.get<{ brands: string[]; language: string[] }>(`api/client/ads/filters`)
		);
	}

	async getUserAdFilters(user_id: number): Promise<{ brands: string[]; language: string[] }> {
		return apiService.responseHandler(
			await apiService.get<{ brands: string[]; language: string[] }>(
				`api/client/user/${user_id}/ads/filters`
			)
		);
	}
}

export const adsApiService = new AdsApiService();
