import { useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';



import useDeleteAd from 'shared/api/hooks/ads/useDeleteAd';
import useSaveAd from 'shared/api/hooks/ads/useSaveAd';
import { useUpdateAdTags } from 'shared/api/hooks/ads/useUpdateAdTags';
import { useCopyPublicAdLink } from 'shared/api/hooks/public/useCopyPublicAdLink';
import { Ad } from 'shared/models/ads';



import { UserDTO } from 'core/api/models/user';
import { ToastContext } from 'core/toast/ToastProvider';
import { openModal } from 'features/detailsModal/detailsModalSlice';


export const useCard = ({
	ad,
	user,
	updateAdTags,
	deleteMutation,
	saveMutation,
	checkedTagIds,
	setCheckedTagIds,
	copyLinkMutation,
	adSource,
}: {
	ad: Ad | undefined;
	user: UserDTO | null;
	updateAdTags: ReturnType<typeof useUpdateAdTags>;
	saveMutation: ReturnType<typeof useSaveAd>;
	deleteMutation: ReturnType<typeof useDeleteAd>;
	checkedTagIds: number[];
	setCheckedTagIds: React.Dispatch<React.SetStateAction<number[]>>;
	copyLinkMutation: ReturnType<typeof useCopyPublicAdLink>;
	adSource?: string;
}) => {
	const toast = useContext(ToastContext);
    const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(null);
	const [submenuOpen, setSubmenuOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const dispatch = useDispatch();

	const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	}, []);

	const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 's') {
			event.stopPropagation();
		}
	}, []);

	const closeAll = useCallback(() => {
		setAnchorEl(null);
		setSubMenuAnchorEl(null);
		setSubmenuOpen(false);
	}, []);

	const handleSaveAdClick = useCallback((event: React.MouseEvent<HTMLLIElement>) => {
		setSubMenuAnchorEl(event.currentTarget);
		setSubmenuOpen(true);
	}, []);

	const handleCloseMenu = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const handleCloseSubMenu = useCallback(() => {
		setSubMenuAnchorEl(null);
		setSubmenuOpen(false);
	}, []);

	const handleSaveClick = useCallback(() => {
		if (ad && user) {
			if (ad.user_ad_id) {
				updateAdTags.mutate({
					userId: user.id,
					adId: ad.user_ad_id,
					tagData: checkedTagIds,
				});
			} else {
				saveMutation.mutate({ userId: user.id, adId: ad._id, tagData: checkedTagIds });
			}
		}
		setCheckedTagIds([]);
		closeAll();
	}, [ad, user, updateAdTags, saveMutation, checkedTagIds, setCheckedTagIds, closeAll]);

	const handleDelete = useCallback(() => {
		if (!user) {
			navigate('/login');
			return;
		}
		if (user && ad && ad.user_ad_id) {
			deleteMutation.mutate({ userId: user.id, adId: ad.user_ad_id, publicAdId: ad._id });
		}
	}, [user, ad, deleteMutation, navigate]);

	const handleCopyLink = useCallback(() => {
		copyLinkMutation.mutate();
		handleCloseMenu();
	}, [copyLinkMutation, handleCloseMenu]);

	const handleTrackBrandClick = useCallback(() => {
		if (!ad?.brand_id) {
			toast.open('Error', 'Unable to track this brand', {
				severity: 'error',
			});
			return;
		}

		const link = `https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&view_all_page_id=${ad.brand_id}&search_type=page&media_type=all`;

		toast.open('Success', 'You will be redirected to tracker', {
			severity: 'success',
		});

		setTimeout(() => {
			navigate('/tracker', { state: { brandUrl: link } });
		}, 1000);
	}, [ad, toast, navigate]);

	const handleViewDetails = useCallback(
		(adId: string | undefined) => {
			if (adId && adSource) {
				dispatch(openModal({ ad_id: adId, adSource }));
			}
		},
		[adSource, dispatch]
	);

	const handleToggleSaved = useCallback(() => {
		if (!user) {
			navigate('/login');
			return;
		}
		if (user && ad) {
			if (ad.user_ad_id) {
				handleDelete();
			} else {
				saveMutation.mutate(
					{ userId: user.id, adId: ad._id },
					{
						onSuccess: () => {
							toast.open('Success', 'Ad saved successfully', {
								severity: 'success',
							});
						},
						onError: () => {
							toast.open('Error', 'Error saving ad', {
								severity: 'error',
							});
						},
					}
				);
			}
		}
	}, [user, ad, handleDelete, saveMutation, toast, navigate]);

	const handleMoreOptionsClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	}, []);

	return {
		anchorEl,
		subMenuAnchorEl,
		submenuOpen,
		closeAll,
		setSubmenuOpen,
		searchTerm,
		handleSearchChange,
		handleKeyDown,
		handleSaveAdClick,
		handleCloseMenu,
		handleCloseSubMenu,
		handleSaveClick,
		handleDelete,
		handleCopyLink,
		handleTrackBrandClick,
		handleToggleSaved,
		handleViewDetails,
		handleMoreOptionsClick,
	};
};