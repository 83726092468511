import { adsApiService } from 'shared/api/services/ads/AdsApiService';

import { UseQueryResult, useQuery } from '@tanstack/react-query';

export const useFetchFilters = (
	adsType: 'userAds' | 'publicAds',
	userId?: number | undefined
): UseQueryResult<{ brands: string[]; languages: string[] }, Error> => {
	const queryFn = async () => {
		if (adsType === 'userAds') {
			if (userId === undefined) {
				throw new Error('User ID must be provided for userAds');
			}
			return await adsApiService.getUserAdFilters(userId);
		} else if (adsType === 'publicAds') {
			return await adsApiService.getAllAdFilters();
		}
	};
	return useQuery({
		queryKey: ['ad_filters', userId, adsType],
		queryFn: queryFn,
		staleTime: 3 * 60 * 1000,
	});
};
