import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Box, Button, FormControlLabel, Switch, Typography } from '@mui/material';

import { Search } from 'shared/components';
import { FilterContext } from 'shared/context/FilterContext';
import { RoutesEnum } from 'shared/enums';
import { useGridZoomHook, useToggleOptionsHook } from 'shared/hooks/useAdsManagement';
import { Tag } from 'shared/models/tags';

import { ChevronLeftDoubleIcon } from '../Icons';
import { SortControl } from './SortControl';
import { TagsControl } from './TagsControl';
import { VisibilityControl } from './VisibilityControl';
import { ZoomControl } from './ZoomControl';
import { RootState } from 'app/store/store';
import { selectFilteredAds } from 'features/ads/selectors';
import { toggleTag } from 'features/tags/tagsSlice';

import styles from './AdsControls.module.scss';

interface PageControlsProps {
	adSource: string;
	adsCount: number | undefined | null;
	handleSearch?: (arg: string) => void;
	resetSearch?: () => void;
	tags?: Tag[];
	tagId?: number | null;
	searchBox?: boolean;
	cardStyle?: boolean;
	publicView?: boolean;
	isLoadingTotal?: boolean;
	isLoading: boolean;
	hasToggle?: boolean;
}
type ToggleOption = 'recentlyScaledAds';

const PageControls: React.FC<PageControlsProps> = ({
	adSource,
	adsCount,
	handleSearch,
	resetSearch,
	tags,
	tagId,
	searchBox = false,
	cardStyle = false,
	publicView,
	isLoadingTotal,
	isLoading,
	hasToggle,
}) => {
	const [mainTag, setMainTag] = useState<Tag | undefined>(undefined);
	const location = useLocation();
	const { zoom, handleZoomChange } = useGridZoomHook();
	const { filters, handleOpen } = useContext(FilterContext);
	const { toggleOptions, toggleOption } = useToggleOptionsHook();

	const selectedTags = useSelector((state: RootState) => state.tags.selectedTags || []);
	const searchConducted = useSelector((state: RootState) => state.search.searchConducted);
	const searchResults = useSelector((state: RootState) => state.search.searchResults);
	const filteredAds = useSelector((state: RootState) => selectFilteredAds(state, adSource));
	const dispatch = useDispatch();
	const displayTotalCount =
		location.pathname === RoutesEnum.DISCOVERY ||
		`/${location.pathname.split('/')[1]}` === RoutesEnum.BRAND;
	const displayCount = filters.length > 0 ? filteredAds.length : adsCount;
	const adsCountSubtitle = location.pathname.includes(RoutesEnum.TAGS)
		? `Total ads: ${displayCount}`
		: displayTotalCount
			? ''
			: `Saved ads: ${displayCount}`;

	useEffect(() => {
		if (tags && tagId) {
			let mainTag;
			let isSubTag = false;
			for (const tag of tags) {
				const foundSubTag = tag.subTags?.find((subTag) => subTag.id === tagId);
				if (foundSubTag) {
					mainTag = tag;
					isSubTag = true;
					break;
				}
			}

			if (!isSubTag) {
				mainTag = tags.find((tag) => tag.id === tagId);
			}
			setMainTag(mainTag);
		}
	}, [tags, tagId]);

	const handleSelectTag = useCallback(
		(tagId: number) => {
			dispatch(toggleTag({ tagId, allowEmpty: false }));
		},
		[dispatch]
	);

	const handleToggleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name as ToggleOption;
		toggleOption(name);
	};

	return (
		<Box
			className={cardStyle ? styles.cardBoxContainer : styles.boxContainer}
			sx={{
				backgroundColor: publicView ? '#fff' : 'var(--background-default)',
				margin: cardStyle ? 0 : '0 -24px',
				padding: cardStyle ? '16px 0' : '16px 24px',
			}}
		>
			{searchBox && handleSearch && resetSearch ? (
				<Search
					onSearch={handleSearch}
					resetSearch={() => resetSearch()}
					searchConducted={true}
				/>
			) : (
				<>
					{isLoadingTotal || isLoading ? (
						<Typography variant="subtitle1">Loading...</Typography>
					) : (
						<Typography variant="subtitle1">
							{searchConducted
								? `Search results: ${searchResults?.length}`
								: adsCountSubtitle}
						</Typography>
					)}
				</>
			)}

			<Box className={styles.controls}>
				{hasToggle && (
					<FormControlLabel
						sx={{ flexDirection: 'row-reverse', marginRight: '-5px' }}
						control={
							<Switch
								name="recentlyScaledAds"
								checked={toggleOptions.recentlyScaledAds}
								onChange={handleToggleSwitch}
								color="primary"
							/>
						}
						label="Recently Scaled Ads"
					/>
				)}
				{mainTag && (
					<TagsControl
						tag={mainTag}
						selectedTags={selectedTags}
						onSelect={handleSelectTag}
					/>
				)}
				<ZoomControl zoom={zoom} setZoom={handleZoomChange} />

				<VisibilityControl />
				<SortControl />
				{!location.pathname.includes(RoutesEnum.TAG_PUBLIC_VIEW) && (
					<Button
						className={styles.filterButton}
						variant="contained"
						onClick={handleOpen}
					>
						<ChevronLeftDoubleIcon
							sx={{ width: '21px', height: '20px', fill: 'none' }}
						/>
						Filters
					</Button>
				)}
			</Box>
		</Box>
	);
};

const MemoizedPageControls = React.memo(PageControls);

export default MemoizedPageControls;
