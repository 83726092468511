import { FC, lazy, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { PrivateRoute } from 'shared/components/PrivateRoute';
import { PublicRoute } from 'shared/components/PublicRoute';
import { FilterProvider } from 'shared/context/FilterContext';
import { RoutesEnum } from 'shared/enums/RoutesEnum';

import { ConfirmEmail } from './pages/auth/components/ConfirmEmail';
import PasswordResetFlow from './pages/auth/components/PasswordResetFlow/PasswordResetFlow';
import Checkout from './pages/checkout';
import ExternalTagFeature from './pages/externalTagFeature';
import PaymentStatus from './pages/paymentStatus';
import SubscriptionConfirmation from './pages/subscriptionConfirmation';
import * as Sentry from '@sentry/react';
import { GoogleCallback, Login, Signup, VerifyEmail } from 'app/pages/auth';

const TopScalingAds = lazy(() => import('./pages/topScalingAds'));
const SwipeFile = lazy(() => import('./pages/swipeFile'));
const Tracker = lazy(() => import('./pages/tracker'));
const BrandDetails = lazy(() => import('./pages/brandDetails'));
const TagFeature = lazy(() => import('./pages/tagFeature'));
const Settings = lazy(() => import('./pages/settings'));
const PublicAdDetails = lazy(() => import('./pages/publicAdDetails'));
const NewAd = lazy(() => import('./pages/newAd'));
const Discovery = lazy(() => import('./pages/discovery'));
const Overview = lazy(() => import('./pages/overview'));

const App: FC = () => {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
		const body = document.body;
		const noScrollbarRoutes = [
			'/login',
			'/signup',
			'/login/callback',
			'/verify-email',
			'/confirm_email',
			'/reset_password',
		];

		if (noScrollbarRoutes.includes(location.pathname)) {
			body.classList.remove('body-scrollbar-visible');
		} else {
			body.classList.add('body-scrollbar-visible');
		}
	}, [location.pathname]);

	function FallbackComponent() {
		return <div>An error has occurred</div>;
	}

	const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

	return (
		<Sentry.ErrorBoundary fallback={<FallbackComponent />} showDialog>
			<SentryRoutes>
				<Route element={<PublicRoute />}>
					<Route path={RoutesEnum.LOGIN} element={<Login />} />
					<Route path={RoutesEnum.GOOGLE_CALLBACK} element={<GoogleCallback />} />
					<Route path={RoutesEnum.SIGNUP} element={<Signup />} />
					<Route path={RoutesEnum.VERIFYEMAIL} element={<VerifyEmail />} />
					<Route path={RoutesEnum.CONFIRM_EMAIL} element={<ConfirmEmail />} />
					<Route path={RoutesEnum.RESETPASSWORD} element={<PasswordResetFlow />} />
					<Route
						path={`${RoutesEnum.AD_PUBLIC_DETAILS}/:token`}
						element={<PublicAdDetails />}
					/>
					<Route
						path={`${RoutesEnum.TAG_PUBLIC_VIEW}/:token`}
						element={<ExternalTagFeature />}
					/>
				</Route>
				<Route element={<PrivateRoute />}>
					<Route path={RoutesEnum.HOME} element={<Overview />} />

					<Route
						path={RoutesEnum.SWIPE}
						element={
							<FilterProvider>
								<SwipeFile />
							</FilterProvider>
						}
					>
						<Route path={RoutesEnum.NEWAD} element={<NewAd />} />
					</Route>
					<Route
						path={RoutesEnum.DISCOVERY}
						element={
							<FilterProvider>
								<Discovery />
							</FilterProvider>
						}
					>
						<Route path={RoutesEnum.NEWAD} element={<NewAd />} />
					</Route>
					<Route path={RoutesEnum.TRACKER} element={<Tracker />}>
						<Route path={RoutesEnum.TOP_10_ADS} element={<TopScalingAds />} />
					</Route>

					<Route
						path={`${RoutesEnum.BRAND}/:id`}
						element={
							<FilterProvider>
								<BrandDetails />
							</FilterProvider>
						}
					></Route>
					<Route path={`${RoutesEnum.HOME}/:tagId`} element={<TagFeature />} />
					<Route
						path={`${RoutesEnum.TAGS}/:tagId`}
						element={
							<FilterProvider>
								<TagFeature />
							</FilterProvider>
						}
					>
						<Route path={RoutesEnum.NEWAD} element={<NewAd />} />
					</Route>
					<Route path={RoutesEnum.SETTINGS} element={<Settings />} />
					<Route path={RoutesEnum.PLANS} element={<>Plans section!</>} />
					<Route path={`${RoutesEnum.BRAND}/:id`} element={<BrandDetails />} />

					<Route path={RoutesEnum.CHECKOUT} element={<Checkout />} />
					<Route
						path={RoutesEnum.SUBSCRIPTION_CONFIRMATION}
						element={<SubscriptionConfirmation />}
					/>
					<Route path={RoutesEnum.PAYMENTSTATUS} element={<PaymentStatus />} />
				</Route>
				<Route path="*" element={<>404!</>} />
			</SentryRoutes>
		</Sentry.ErrorBoundary>
	);
};

export default App;
