import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Download from '@mui/icons-material/Download';
import { CircularProgress, Typography } from '@mui/material';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import Container from '@mui/material/Container/Container';

import { useGetAd } from 'shared/api/hooks/ads/useGetAd';
import { ExternalLinkIcon } from 'shared/components/Icons';
import { Spinner } from 'shared/components/Spinner';
import { Ad } from 'shared/models/ads';
import { calculateAdSpend } from 'shared/utils/calculate-ad-spend';

import { DetailsCardView, DetailsHeader, DetailsTabs } from './components';
import LoadingState from './components/LoadingState/LoadingState';
import { requestNextPagePublicAds } from 'features/ads/adsSlice';
import { closeModal } from 'features/detailsModal/detailsModalSlice';

import sharedStyles from './AdDetails.module.scss';
import { RootState } from 'app/store/store';

interface TagAdDetailsProps {
	token?: string | undefined;
}

const AdDetails: FC<TagAdDetailsProps> = ({ token }) => {
	const { ad_id } = useSelector((state: any) => state.detailsModal);
 const { currentMediaIndex, currentSubformat } = useSelector((state: RootState) => state.media);
	const location = useLocation();
	const dispatch = useDispatch();
	const [modalVisible, setModalVisible] = useState(false);
	// const navigate = useNavigate();

	// const initialAdSource = location.state?.adSource || '';

	// const ad_id = location.state?.id;

	const queryParams = new URLSearchParams(location.search);
	const adId = queryParams.get('ad_id');
	const [currentId, setCurrentId] = useState(ad_id);
	const [adSpend, setAdSpend] = useState<string>('');
	const [thumbnailLoading, setThumbnailLoading] = useState(false);

	const adsData = useSelector((state: any) => state.ads.publicAds);

	const { filteredAds, hasNextPage, isLoading, nextPage } = adsData;
	const {
		data: ad,
		isLoading: loading,
		error,
	} = useGetAd(currentId || ad_id || adId, 'publicAds', undefined, token);

	// const { id } = useParams<{ id: string }>();

	const [imageAspect] = useState('portrait');
	const [currentIndex, setCurrentIndex] = useState(
		() => filteredAds?.findIndex((ad: any) => ad._id === ad_id) ?? -1
	);

	useEffect(() => {
		setModalVisible(true);
	}, []);

	useEffect(() => {
		const index = filteredAds?.findIndex((ad: Ad) => ad._id.toString() === adId) ?? -1;

		setCurrentIndex(index);
	}, [adId, filteredAds]);

	const handleFetchNextPage = useCallback(() => {
		dispatch(requestNextPagePublicAds());
	}, [dispatch]);

	useEffect(() => {
		if (typeof currentIndex === 'number' && filteredAds && filteredAds.length > 0)
			if (currentIndex === filteredAds.length - 2 && hasNextPage) {
				handleFetchNextPage();
			}
	}, [nextPage, hasNextPage, currentIndex, filteredAds, handleFetchNextPage]);
	useEffect(() => {
		const newIndex: number = filteredAds?.findIndex((ad: Ad) => ad._id === currentId) ?? -1;
		if (newIndex !== -1) {
			setCurrentIndex(newIndex);
		} else {
			setCurrentIndex(0);
		}
	}, [currentId, filteredAds]);

	useEffect(() => {
		if (ad?.ad_spend) {
			setAdSpend(calculateAdSpend(ad.ad_spend));
		}
	}, [ad]);

	const handleNextAd = () => {
		if (typeof currentIndex === 'number' && filteredAds && filteredAds.length > 0) {
			if (currentIndex >= 0 && currentIndex < filteredAds.length - 1) {
				const nextAdId = filteredAds[currentIndex + 1]._id;
				setCurrentId(nextAdId);
			}
		}
	};

	const handlePrevAd = () => {
		if (typeof currentIndex === 'number' && filteredAds && filteredAds.length > 0) {
			if (currentIndex > 0) {
				const prevAdId = filteredAds[currentIndex - 1]._id;
				setCurrentId(prevAdId);
			}
		}
	};

	const handleClose = () => {
		dispatch(closeModal());
	};

	const downloadUrl = ad?.mediaFiles ? ad.mediaFiles[currentMediaIndex]?.mediaUrl : undefined;

	const openMediaInNewTab = (downloadUrl: string) => {
		const newTab = window.open(downloadUrl, '_blank');
		if (!newTab) {
			console.error('Failed to open image in new tab');
		}
	};

	const openThumbnailInNewTab = (downloadUrl: string) => {
		setThumbnailLoading(true);
		const video = document.createElement('video');
		video.src = downloadUrl;
		video.crossOrigin = 'anonymous';

		video.addEventListener('loadedmetadata', () => {
			video.currentTime = 1;
		});

		video.addEventListener('seeked', () => {
			const canvas = document.createElement('canvas');
			canvas.width = video.videoWidth;
			canvas.height = video.videoHeight;
			const ctx = canvas.getContext('2d');
			if (ctx) {
				ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

				const imageUrl = canvas.toDataURL('image/jpeg');

				const newTab = window.open(imageUrl, '_blank');
				if (newTab) {
					setThumbnailLoading(false);
					newTab.document.write('<img src="' + imageUrl + '">');
				} else {
					setThumbnailLoading(false);
					console.error('Failed to open image in new tab');
				}
			} else {
				setThumbnailLoading(false);
				console.error('Unable to get canvas context');
			}
		});
	};

	return (
		<Container
			className={`${sharedStyles.modalContainer} ${modalVisible ? sharedStyles.active : ''}`}
		>
			{error ? (
				<Box className={sharedStyles.modalContent}>
					<Typography>Error: {error.message || 'Ad not found'}</Typography>
				</Box>
			) : isLoading ? (
				<Spinner />
			) : (
				<Box className={sharedStyles.modalContent}>
					<DetailsHeader
						userAd={ad?.user_ad_id}
						id={ad?.library_id || ad?.external_id || ad?.user_ad_id || 'Loading...'}
						handleClose={handleClose}
						handleNextAd={handleNextAd}
						handlePrevAd={handlePrevAd}
						isNextDisabled={currentIndex >= (filteredAds?.length ?? 0) - 1}
						isPrevDisabled={currentIndex <= 0}
					/>

					<Box className={`${sharedStyles.detailsContent} ${sharedStyles[imageAspect]}`}>
						<Box className={sharedStyles.detailsLeft}>
							<DetailsCardView ad={ad} />
							<Box className={sharedStyles.downloadButtons}>
								{downloadUrl && (
									<Button
										endIcon={<Download />}
										variant="outlined"
										color="primary"
										onClick={() => openMediaInNewTab(downloadUrl)}
									>
										Download Ad media
									</Button>
								)}
								{currentSubformat === 'video' && downloadUrl && (
									<Button
										endIcon={
											thumbnailLoading ? (
												<CircularProgress size={16} />
											) : (
												<ExternalLinkIcon
													sx={{ width: '16px', height: '16px' }}
												/>
											)
										}
										variant="outlined"
										color="primary"
										onClick={() => openThumbnailInNewTab(downloadUrl)}
									>
										Download Thumbnail
									</Button>
								)}
							</Box>
						</Box>
						<Box className={sharedStyles.detailsRight}>
							{loading ? (
								<LoadingState />
							) : (
								<DetailsTabs
									ad={ad}
									adSource="publicAds"
									adSpend={adSpend}
									external
								/>
							)}
						</Box>
					</Box>
				</Box>
			)}
		</Container>
	);
};

export default AdDetails;
