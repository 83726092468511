import { useState } from 'react';

import { adsApiService } from 'shared/api/services/ads/AdsApiService';
import { FiltersProps } from 'shared/context/FilterContext';

import * as Sentry from '@sentry/react';
import { useInfiniteQuery } from '@tanstack/react-query';

export const useFetchAds = (
	adsType: string,
	userId: number | undefined,
	sort: string,
	filters: FiltersProps[],
	tagIds: number[] = [],
	search: string = '',
	isReadyToFetch: boolean,
	recentlyScaledAds?: boolean,
	brandId?: string | undefined,
	topAds?: boolean,
	tailoredAds?: boolean
) => {
	const [totalAdsCount, setTotalAdsCount] = useState(0);
	const [isLoadingTotal, setIsLoadingTotal] = useState(true);

	const fetchAds = async ({ pageParam = 1 }) => {
		const tagsQueryString = tagIds.length > 0 ? `&tag=${tagIds.join(',')}` : '';
		let response;
		setIsLoadingTotal(true);

		try {
			if (adsType === 'userAds') {
				response = await adsApiService.getUserAds(
					userId,
					pageParam,
					sort,
					filters,
					tagsQueryString,
					search
				);
			} else {
				response = await adsApiService.getAds(
					userId,
					pageParam,
					sort,
					filters,
					search,
					brandId,
					recentlyScaledAds,
					topAds,
					tailoredAds
				);
			}
			setTotalAdsCount(response.totalCount);
		} catch (error) {
			Sentry.captureException(error, {
				tags: {
					queryKey: adsType === 'userAds' ? 'user-ads' : 'ads',
				},
				extra: {
					userId,
					adsType,
					pageParam,
					filters,
					tagIds,
					search,
					brandId,
					topAds,
				},
			});
			throw error;
		} finally {
			setIsLoadingTotal(false);
		}

		return response;
	};

	const getQueryKey = () => {
		const baseKey = adsType === 'userAds' && userId ? ['user-ads', userId] : ['ads'];

		if (tagIds.length) {
			baseKey.push('tags', ...tagIds.map(String));
		}
		if (search) {
			baseKey.push('search', search);
		}
		if (brandId) {
			baseKey.push('brand-id', brandId);
		}
		if (topAds) {
			baseKey.push('top-ads');
		}
		if (tailoredAds) {
			baseKey.push('tailored-ads');
		}
		if (recentlyScaledAds) {
			baseKey.push('recently-scaled-ads');
		}
		return [...baseKey, sort, filters];
	};

	return {
		...useInfiniteQuery({
			queryKey: getQueryKey(),
			queryFn: fetchAds,
			enabled: isReadyToFetch,
			getNextPageParam: (_, allPages) => {
				const PAGE_SIZE = 30;
				const allFetchedAdsCount = allPages.reduce(
					(total, page) => total + page.ads.length,
					0
				);

				const lastPage = allPages[allPages.length - 1];
				const isLastPageFull = lastPage.ads.length === PAGE_SIZE;
				if (isLastPageFull) {
					const nextPage = Math.ceil(allFetchedAdsCount / PAGE_SIZE) + 1;
					return nextPage;
				}
				return undefined;
			},
			initialPageParam: 1,
		}),
		totalAdsCount,
		isLoadingTotal,
	};
};
