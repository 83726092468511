import { UserUpdateRequest } from 'shared/models/UserUpdateRequest';
import { QuizQuestion } from 'shared/models/quizQuestions';

import { UserStatistics } from 'app/pages/overview/models/UserStatistics';
import { UserDTO } from 'core/api/models/user';
import { apiService } from 'core/api/services';

export class UserApiService {
	async getUser(id: number | null): Promise<UserDTO> {
		return apiService.responseHandler(await apiService.get<UserDTO>(`/api/client/user/${id}`));
	}

	async updateUser(id: number, body: UserUpdateRequest | FormData): Promise<UserDTO> {
		return apiService.responseHandler(
			await apiService.patch(`/api/client/user/${id}`, body, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
		);
	}

	async getStatistics(id: number): Promise<UserStatistics> {
		return apiService.responseHandler(
			await apiService.get<UserStatistics>(`/api/client/user/${id}/user-statistics`)
		);
	}

	async getQuizQuestions(id: number | undefined): Promise<QuizQuestion[]> {
		return apiService.responseHandler(
			await apiService.get<QuizQuestion[]>(`/api/client/user/${id}/quiz/onboarding`)
		);
	}

	async postQuizAnswers(
		userId: number | undefined,
		questionId: string,
		answerIds: string[]
	): Promise<unknown> {
		return apiService.responseHandler(
			await apiService.post<{ answerIds: string[] }>(
				`/api/client/user/${userId}/quiz/onboarding/${questionId}`,
				{
					answer: answerIds,
				}
			)
		);
	}
}

export const userApiService = new UserApiService();
