import { FC, useCallback, useEffect, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Masonry from 'react-masonry-css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import { Ad } from 'shared/models/ads';

import { Spinner } from '../Spinner';
import { AdCard } from './AdCard';
import { AdGridSkeleton } from './AdGridSkeleton';
import { RootState } from 'app/store/store';
import { requestNextPagePublicAds, requestNextPageUserAds } from 'features/ads/adsSlice';
import { selectFilteredAds } from 'features/ads/selectors';

import styles from './AdGrid.module.scss';

export interface AdGridProps {
	adSource: string;
	hasNextPage: boolean;
	isFetchingNextPage: boolean;
	error?: Error | null;
	isLoading?: boolean;
	publicView?: boolean;
	externalView?: boolean;
}

const AdGrid: FC<AdGridProps> = ({
	adSource,
	hasNextPage,
	isLoading,
	publicView,
	externalView,
	isFetchingNextPage,
}) => {
	const location = useLocation();
	const { breakpointCols } = useSelector((state: RootState) => state.gridZoom);
	const [adsLoading, setAdsLoading] = useState(true);

	const dispatch = useDispatch();
	const ads = useSelector((state: RootState) => selectFilteredAds(state, adSource));

	const [adsList, setAdsList] = useState<Ad[] | undefined>(undefined);

	useEffect(() => {
		if (!isLoading) {
			setAdsList(ads);
		}
		setAdsLoading(false);
	}, [ads, isLoading]);

	const handleFetchNextPage = useCallback(() => {
		if (adSource === 'userAds') {
			dispatch(requestNextPageUserAds());
		} else if (adSource === 'publicAds') {
			dispatch(requestNextPagePublicAds());
		}
	}, [adSource, dispatch]);

	const [sentryRef] = useInfiniteScroll({
		loading: isLoading ?? false,
		hasNextPage: hasNextPage,
		onLoadMore: handleFetchNextPage,
		rootMargin: '600px 0px',
	});

	if (isLoading || adsLoading) {
		return <AdGridSkeleton />;
	}

	return (
		<Box
			className={styles.grid_container}
			sx={externalView ? { px: '16px', py: '24px' } : null}
		>
			{adsList ? (
				<>
					<Masonry
						breakpointCols={breakpointCols}
						className={styles.masonryGrid}
						columnClassName={styles.gridColumn}
					>
						{adsList.map((ad) => (
							<AdCard
								key={ad._id}
								publicView={publicView}
								adSource={adSource}
								actions
								ad={ad}
								showTrackOption={!location.pathname.includes('top-10-ads')}
							/>
						))}
					</Masonry>
					{hasNextPage && (
						<div ref={sentryRef} style={{ height: '70px', position: 'relative' }}>
							{isFetchingNextPage && <Spinner />}
						</div>
					)}
				</>
			) : null}
		</Box>
	);
};

export default AdGrid;
