import { FC, PropsWithChildren, createContext, useEffect, useState } from 'react';

import { UserDTO } from 'core/api/models/user';

export interface UserActions {
	setLoggedInUser: (userData: UserDTO) => void;
	logoutUser: () => void;
	updateTokens: (access_token: string, refresh_token: string) => void;
	updateUser: (updatedUser: Partial<UserDTO>) => void;
}

export interface UserContextType {
	user: UserDTO | null;
	actions: UserActions;
}

export const UserContext = createContext<UserContextType | undefined>(undefined);

const UserProvider: FC<PropsWithChildren> = ({ children }) => {
	const initialUser = () => {
		const storedUser = localStorage.getItem('user');
		return storedUser ? JSON.parse(storedUser) : null;
	};

	const [user, setUser] = useState<UserDTO | null>(initialUser);

	useEffect(() => {
		const storedUser = localStorage.getItem('user');
		if (storedUser) {
			setUser(JSON.parse(storedUser));
		}
	}, []);

	useEffect(() => {
		if (user) {
			localStorage.setItem('user', JSON.stringify(user));
		} else {
			localStorage.removeItem('user');
		}
	}, [user]);

	const setLoggedInUser = (userData: UserDTO) => {
		setUser(userData);
		localStorage.setItem('user', JSON.stringify(userData));
		window.postMessage({ type: 'SET_USER', user: userData, source: 'adUniverse' }, '*');
	};

	const logoutUser = () => {
		setUser(null);
		localStorage.removeItem('user');
		window.postMessage({ type: 'REMOVE_USER', source: 'adUniverse' }, '*');
	};

	const updateTokens = (access_token: string, refresh_token: string) => {
		setUser((currentUser) => {
			if (!currentUser) return null;
			const updatedUser = { ...(currentUser as UserDTO), access_token, refresh_token };

			localStorage.setItem('user', JSON.stringify(updatedUser));
			window.postMessage({ type: 'SET_USER', user: updatedUser, source: 'adUniverse' }, '*');

			return updatedUser;
		});
	};

	const updateUser = (updatedUser: Partial<UserDTO>) => {
		setUser((currentUser) => {
			if (!currentUser) return null;
			const newUser = { ...(currentUser as UserDTO), ...updatedUser };
			localStorage.setItem('user', JSON.stringify(newUser));
			window.postMessage({ type: 'SET_USER', user: newUser, source: 'adUniverse' }, '*');

			return newUser;
		});
	};

	const value: UserContextType = {
		user,
		actions: {
			setLoggedInUser,
			logoutUser,
			updateTokens,
			updateUser,
		},
	};

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;