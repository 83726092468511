import { useEffect } from 'react';

const useScrollLock = (anchorEl: HTMLElement | null) => {
	useEffect(() => {
		if (!anchorEl) return;

		const scrollTop = window.scrollY || document.documentElement.scrollTop;
		const scrollLeft = window.scrollX || document.documentElement.scrollLeft;

		const handleScroll = () => {
			window.scrollTo(scrollLeft, scrollTop);
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [anchorEl]);
};

export default useScrollLock;
