import { useEffect, useState } from 'react';
import { Ad } from 'shared/models/ads';

export const useCardMedia = (ad: Ad | undefined) => {
  const [currentSubFormat, setCurrentSubFormat] = useState<string | undefined>(
    ad?.mediaFiles && ad.mediaFiles.length > 0 ? ad.sub_format[0] : ''
  );

  const [playerInitialized, setPlayerInitialized] = useState<boolean[]>(
    () => (ad?.mediaFiles ? ad.mediaFiles.map(() => false) : [])
  );

  useEffect(() => {
    if (ad && ad.mediaFiles.length > 0) {
      setCurrentSubFormat(ad.sub_format[0]);
    }
  }, [ad]);

  return { currentSubFormat, setCurrentSubFormat, playerInitialized, setPlayerInitialized };
};