import { FC, PropsWithChildren } from 'react';

// import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal, Typography } from '@mui/material';

import styles from './ModalContainer.module.scss';

interface ModalContainerProps extends PropsWithChildren {
	isOpen: boolean;
	title?: string;
	onClose?: VoidFunction;
	overflowHidden?: boolean
}

export const ModalContainer: FC<ModalContainerProps> = ({ isOpen, title, overflowHidden, children }) => {
	const handleBackdropClick = (event: React.MouseEvent) => {
		event.stopPropagation();
	};

	return (
		<Modal
			open={isOpen}
			className={styles.container}
			onClose={handleBackdropClick}
			disableEscapeKeyDown
		>
			<Box className={styles.content} style={{overflowY: overflowHidden ? 'unset' : 'auto'}} onClick={handleBackdropClick}>
				{title && (
					<Box className={styles.modal_header}>
						<Typography variant="h3" className={styles.title}>
							{title}
						</Typography>
						{/* <IconButton
						edge="end"
						color="inherit"
						onClick={onClose}
						aria-label="close"
						className={styles.closeButton}
					>
						<CloseIcon />
					</IconButton> */}
					</Box>
				)}

				{children}
			</Box>
		</Modal>
	);
};
