import React, { ForwardedRef } from 'react';
import { useSelector } from 'react-redux';

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, IconButton, InputAdornment, ListItemIcon, ListItemText, Menu, MenuItem, SvgIcon, TextField, Tooltip } from '@mui/material';

import CustomCheckIcon from 'shared/components/CustomCheckIcon';
import CustomCheckbox from 'shared/components/CustomCheckbox';
import { HeartRoundedIcon, HeartRoundedIconOutlined, SearchIcon } from 'shared/components/Icons';
import { Ad } from 'shared/models/ads';

import { RootState } from 'app/store/store';
import { useSaveAdSubMenu } from '../hooks/useSubMenu';

import styles from '../AdCard.module.scss';

interface SaveAdSubMenuProps {
	ad: Ad | undefined;
	subMenuAnchorEl: HTMLElement | null;
	handleCloseSubMenu: () => void;
	searchTerm: string;
	handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	handleSaveClick: () => void;
	setCheckedTagIds: React.Dispatch<React.SetStateAction<number[]>>;
}

const SaveAdSubMenu = React.forwardRef(function SaveAdSubMenu(
	{
		ad,
		subMenuAnchorEl,
		handleCloseSubMenu,
		searchTerm,
		handleSearchChange,
		handleKeyDown,
		handleSaveClick,
		setCheckedTagIds,
	}: SaveAdSubMenuProps,
	ref: ForwardedRef<HTMLDivElement | null>
) {
	const { tags } = useSelector((state: RootState) => state.tags);

	const {
		filteredTags,
		expandedTags,
		handleTagChange,
		hasTagsChanged,
		toggleExpandedTags,
		checkedTagIds,
	} = useSaveAdSubMenu({
		ad,
		tags,
		searchTerm,
		setCheckedTagIds,
	});

	return (
		<Menu
			id="save-ad-menu"
			anchorEl={subMenuAnchorEl}
			open={Boolean(subMenuAnchorEl)}
			onClose={handleCloseSubMenu}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			slotProps={{
				paper: {
					ref: ref,
					style: {
						marginLeft: -2,
						marginTop: -8,
						width: 220,
					},
				},
			}}
		>
			<MenuItem sx={{ background: 'rgba(248, 249, 250, 1)' }}>
				<TextField
					disabled={tags.length === 0}
					placeholder="Search Tags"
					value={searchTerm}
					onChange={handleSearchChange}
					onKeyDown={handleKeyDown}
					fullWidth
					variant="outlined"
					size="small"
					sx={{ '.MuiInputBase-root': { paddingRight: '6px' } }}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									sx={{ width: '24px', height: '24px' }}
									aria-label="search tags"
								>
									<SearchIcon sx={{ width: '14px', height: '14px' }} />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</MenuItem>
			<Box className={styles.tagsListMenu}>
				{filteredTags.map((tag) => (
					<React.Fragment key={tag.id}>
						<MenuItem>
							<ListItemIcon>
								<CustomCheckbox
									checked={checkedTagIds.includes(tag.id)}
									onChange={(e) => handleTagChange(tag.id, e.target.checked)}
									icon={
										<SvgIcon>
											<rect
												x="2"
												y="2"
												width="18"
												height="18"
												rx="4"
												ry="4"
												fill="#fff"
											/>
										</SvgIcon>
									}
									checkedIcon={<CustomCheckIcon />}
								/>
							</ListItemIcon>
							<Tooltip title={`# ${tag.name}`} placement="top">
								<ListItemText
									className={styles.tagName}
									primary={`# ${tag.name}`}
								/>
							</Tooltip>
							{tag.subTags && tag.subTags.length > 0 && (
								<IconButton onClick={() => toggleExpandedTags(tag.id)}>
									{expandedTags[tag.id] ? (
										<KeyboardArrowDown />
									) : (
										<KeyboardArrowRight />
									)}
								</IconButton>
							)}
						</MenuItem>
						{expandedTags[tag.id] &&
							tag.subTags &&
							tag.subTags.map((subTag) => (
								<MenuItem key={subTag.id} style={{ paddingLeft: '30px' }}>
									<ListItemIcon>
										<CustomCheckbox
											checked={checkedTagIds.includes(subTag.id)}
											onChange={(e) =>
												handleTagChange(subTag.id, e.target.checked)
											}
											icon={
												<SvgIcon>
													<rect
														x="2"
														y="2"
														width="18"
														height="18"
														rx="4"
														ry="4"
														fill="#fff"
													/>
												</SvgIcon>
											}
											checkedIcon={<CustomCheckIcon />}
										/>
									</ListItemIcon>
									<Tooltip title={`# ${subTag.name}`} placement="top">
										<ListItemText
											className={styles.tagName}
											primary={`# ${subTag.name}`}
										/>
									</Tooltip>
								</MenuItem>
							))}
					</React.Fragment>
				))}
			</Box>

			<MenuItem sx={{ padding: '8px' }}>
				<Button
					disabled={tags.length === 0 || !hasTagsChanged()}
					startIcon={
						tags.length === 0 || !hasTagsChanged() ? (
							<HeartRoundedIconOutlined
								sx={{ color: '#fff !important', width: '16px', height: '16px' }}
							/>
						) : (
							<HeartRoundedIcon sx={{ width: '16px', height: '16px' }} />
						)
					}
					onClick={handleSaveClick}
					fullWidth
					variant="outlined"
					color="primary"
					sx={{
						'&.Mui-disabled': {
							opacity: 0.5,
							backgroundColor: 'white',
							borderColor: 'rgba(200, 225, 229, 1)',
							color: 'rgba(0, 158, 194, 1)',
						},
					}}
				>
					Save Ad
				</Button>
			</MenuItem>
		</Menu>
	);
});

export default SaveAdSubMenu;