import React, { FC, useRef, useState } from 'react';
import { useDrag } from 'react-dnd';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Card } from '@mui/material';

import useDeleteAd from 'shared/api/hooks/ads/useDeleteAd';
import useSaveAd from 'shared/api/hooks/ads/useSaveAd';
import { useUpdateAdTags } from 'shared/api/hooks/ads/useUpdateAdTags';
import { useCopyPublicAdLink } from 'shared/api/hooks/public/useCopyPublicAdLink';
import { DnDIcon } from 'shared/components/Icons';
import { useOutsideClickMultipleRefs } from 'shared/hooks/useOutsideClickMultipleRefs';
import { Ad } from 'shared/models/ads';

import {
	CardFooter,
	CardInfo,
	CardMediaWrapper,
	CardUserActions,
	MoreOptionsMenu,
	SaveAdSubMenu,
} from './components';
import { useCard } from './hooks/useCard';
import { useCardMedia } from './hooks/useMedia';
import { RootState } from 'app/store/store';
import { useDragContext } from 'core/DragProvider/useDrag';
import { useUser } from 'core/UserProvider/useUser';

import styles from './AdCard.module.scss';

interface AdCardProps {
	adSource?: string;
	ad: Ad | undefined;
	actions?: boolean;
	footer?: boolean;
	detail?: boolean;
	publicView?: boolean;
	showTrackOption?: boolean;
}

const AdCard: FC<AdCardProps> = React.memo(
	({ adSource, ad, actions, footer, detail, publicView, showTrackOption }) => {
		const { user } = useUser();
		const location = useLocation();
		const saveMutation = useSaveAd();
		const { isOpen } = useSelector((state: RootState) => state.detailsModal);

		const menuRef = useRef<HTMLDivElement>(null);
		const subMenuRef = useRef<HTMLDivElement>(null);

		const updateAdTags = useUpdateAdTags();
		const deleteMutation = useDeleteAd();
		const [isHovered, setIsHovered] = useState(false);

		const { currentSubFormat, setCurrentSubFormat, playerInitialized, setPlayerInitialized } =
			useCardMedia(ad);

		const [checkedTagIds, setCheckedTagIds] = useState<number[]>([]);
		const copyLinkMutation = useCopyPublicAdLink(user?.id, ad?._id);

		const {
			anchorEl,
			subMenuAnchorEl,
			submenuOpen,
			closeAll,
			setSubmenuOpen,
			searchTerm,
			handleSearchChange,
			handleKeyDown,
			handleSaveAdClick,
			handleCloseMenu,
			handleCloseSubMenu,
			handleSaveClick,
			handleDelete,
			handleCopyLink,
			handleTrackBrandClick,
			handleToggleSaved,
			handleViewDetails,
			handleMoreOptionsClick,
		} = useCard({
			ad,
			user,
			updateAdTags,
			deleteMutation,
			saveMutation,
			checkedTagIds,
			setCheckedTagIds,
			copyLinkMutation,
			adSource,
		});

		const dragDisabled = isOpen || location.pathname.startsWith('/external');
		const { setIsDragging } = useDragContext();
		const [{ isDragging }, drag] = useDrag(
			() => ({
				type: 'AD_CARD',
				item: () => {
					setIsDragging(true);
					return {
						adId: ad?._id,
						userAdId: ad?.user_ad_id,
						tagData: ad?.tags ?? ad?.user_ad_tags ?? [],
					};
				},
				collect: (monitor) => ({
					isDragging: monitor.isDragging(),
				}),
				end: () => {
					setIsDragging(false);
				},
			}),
			[ad]
		);

		useOutsideClickMultipleRefs([menuRef, subMenuRef], closeAll, !!anchorEl);

		return (
			<Card
				className={styles.cardContainer}
				ref={
					dragDisabled || ad?.format === 'video' || currentSubFormat === 'video'
						? null
						: drag
				}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				style={{
					pointerEvents: isDragging ? 'none' : 'auto',
					cursor: dragDisabled ? 'default' : isDragging ? 'grabbing' : 'grab',
					boxShadow: detail
						? 'none'
						: isHovered
							? '4px 4px 20px 0px rgba(0, 0, 0, 0.15)'
							: 'none',
				}}
			>
				{!dragDisabled && (
					<DnDIcon sx={{ width: '10px', height: '16px' }} className={styles.dragIcon} />
				)}
				<CardInfo
					ad={ad}
					ref={ad?.format === 'video' || currentSubFormat === 'video' ? drag : null}
				/>
				{actions && (
					<CardUserActions
						publicView={publicView}
						handleMoreOptionsClick={handleMoreOptionsClick}
						handleToggleSaved={handleToggleSaved}
						handleViewDetails={handleViewDetails}
						isLoading={
							saveMutation.isPending ||
							updateAdTags.isPending ||
							deleteMutation.isPending
						}
						ad={ad}
					/>
				)}
				<CardMediaWrapper
					ad={ad}
					detail={detail}
					playerInitialized={playerInitialized}
					setPlayerInitialized={setPlayerInitialized}
					setCurrentSubFormat={setCurrentSubFormat}
				/>
				{footer && <CardFooter ad={ad} />}
				{actions && !detail && (
					<>
						<MoreOptionsMenu
							ad={ad}
							anchorEl={anchorEl}
							handleSaveAdClick={handleSaveAdClick}
							handleCopyLink={handleCopyLink}
							handleDelete={handleDelete}
							handleClose={handleCloseMenu}
							submenuOpen={submenuOpen}
							setSubmenuOpen={setSubmenuOpen}
							handleTrackBrand={handleTrackBrandClick}
							showTrackOption={showTrackOption}
							ref={menuRef}
						/>
						<SaveAdSubMenu
							setCheckedTagIds={setCheckedTagIds}
							ad={ad}
							subMenuAnchorEl={subMenuAnchorEl}
							handleCloseSubMenu={handleCloseSubMenu}
							searchTerm={searchTerm}
							handleSearchChange={handleSearchChange}
							handleKeyDown={handleKeyDown}
							handleSaveClick={handleSaveClick}
							ref={subMenuRef}
						/>
					</>
				)}
			</Card>
		);
	}
);

export default AdCard;
