import { FC, Suspense, useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, LinearProgress, Typography } from '@mui/material';

import { useActiveSubscriptionInfo } from 'shared/api/hooks/billing/useActiveSubscriptionInfo';
import PermissionModal from 'shared/components/PermissionModal/PermissionModal';
import { PrivateLayout } from 'shared/components/PrivateLayout';
import { Sidebar } from 'shared/components/Sidebar';
import { RoutesEnum } from 'shared/enums';
import { getDaysLeftV2 } from 'shared/helpers/billingHelpers';
import { useSearchHook } from 'shared/hooks/useAdsManagement';
import { useGetUser } from 'shared/hooks/useGetUser';
import { usePermissionModal } from 'shared/hooks/usePermissionModal';

import BillingAndPlansTabV2 from 'app/pages/settings/components/BillingAndPlansTab/BillingAndPlansTabV2';
import { useGetUsersTeams } from 'app/pages/settings/hooks/useGetUsersTeams';
import { useSidebar } from 'core/SidebarProvider/useSidebar';
import { useUser } from 'core/UserProvider/useUser';
import { ModalContainer } from '../ModalContainer/ModalContainer';

const PrivateRoute: FC = () => {
	const {
		user,
		actions: { setLoggedInUser },
	} = useUser();
	const { data: extendedUser } = useGetUser(user?.id);
	const { data: teams } = useGetUsersTeams();
	const { isOpen, content, handleModalClose, handleModalOpen, setContent } = usePermissionModal();
	const navigate = useNavigate();
	const location = useLocation();
	const { isSidebarOpen } = useSidebar();
	const [isFirstLoginModalOpen, setIsFirstLoginModalOpen] = useState(false);
	const [isSubscriptionChecked, setIsSubscriptionChecked] = useState(false);
	const [contentScriptReady, setContentScriptReady] = useState(false);
	const { handleResetSearch } = useSearchHook();

	const initializePendo = (visitorId: number) => {
		if (window.pendo && visitorId) {
			window.pendo.initialize({
				visitor: { id: visitorId },
				account: { id: visitorId },
			});
		}
	};

	const {
		activeSubscription,
		subscriptionEndsAt,
		isLoading: isSubscriptionLoading,
	} = useActiveSubscriptionInfo(user?.id);
	const isUserAdmin = teams?.owner.length !== 0;

	const handleFirstTimeLogin = useCallback(() => {
		if (user?.metadata?.team_first_login && location.pathname !== RoutesEnum.SETTINGS) {
			setIsFirstLoginModalOpen(true);
		}
	}, [user, location.pathname]);

	const sendUserToExtension = useCallback(() => {
		let updatedUser = user;

		if (!updatedUser) {
			const userString = window.localStorage.getItem('user');
			updatedUser = userString ? JSON.parse(userString) : null;
		}

		if (updatedUser && contentScriptReady) {
			window.postMessage({ type: 'SET_USER', user: updatedUser, source: 'adUniverse' }, '*');
		}
	}, [user, contentScriptReady]);

	useEffect(() => {
		const handleContentScriptReady = (event: MessageEvent) => {
			if (
				event.source === window &&
				event.data &&
				event.data.type === 'CONTENT_SCRIPT_READY' &&
				event.data.source === 'vs-content-script'
			) {
				setContentScriptReady(true);
			}
		};

		window.addEventListener('message', handleContentScriptReady);

		return () => {
			window.removeEventListener('message', handleContentScriptReady);
		};
	}, []);

	useEffect(() => {
		sendUserToExtension();
	}, [user, sendUserToExtension]);

	useEffect(() => {
		const handleUserUpdate = (event: CustomEvent) => {
			const updatedUser = event.detail;
			setLoggedInUser(updatedUser);
		};

		window.addEventListener('refreshToken', handleUserUpdate as EventListener);

		return () => {
			window.removeEventListener('refreshToken', handleUserUpdate as EventListener);
		};
	}, [setLoggedInUser]);

	useEffect(() => {
		if (isSubscriptionLoading) return;
		setIsSubscriptionChecked(true);

		if (
			!extendedUser ||
			[
				RoutesEnum.PAYMENTSTATUS as string,
				RoutesEnum.CHECKOUT as string,
				RoutesEnum.SUBSCRIPTION_CONFIRMATION as string,
			].includes(location.pathname)
		) {
			handleModalClose();
			return;
		}

		const daysLeft = subscriptionEndsAt ? getDaysLeftV2(subscriptionEndsAt) : 'Expired';

		if (daysLeft === 'Expired') {
			!isUserAdmin
				? setContent(
						<Box sx={{ maxWidth: '600px', textAlign: 'center' }}>
							<Typography variant="body1" color={'#495057'}>
								It looks like your subscription plan has come to an end. Please
								reach out to your team owner to renew your plan or explore available
								options. If you have any questions or need assistance, your team
								owner is here to help!
							</Typography>
						</Box>
					)
				: setContent(<BillingAndPlansTabV2 />);
			handleModalOpen();
		} else {
			handleModalClose();
		}
	}, [
		activeSubscription,
		subscriptionEndsAt,
		extendedUser,
		handleModalClose,
		handleModalOpen,
		isUserAdmin,
		isSubscriptionLoading,
		location.pathname,
		setContent,
	]);

	useEffect(() => {
		if (user && user.id && user.metadata?.onboarding_quiz_done && isUserAdmin) {
			initializePendo(user.id);
		}
	}, [user, isUserAdmin]);

	useEffect(() => {
		if (!user && location.pathname !== RoutesEnum.LOGIN) {
			navigate(RoutesEnum.LOGIN);
		}
		if (user && !user.metadata?.onboarding_quiz_done && isUserAdmin) {
			navigate(RoutesEnum.HOME);
		}
	}, [location.pathname, navigate, user, isUserAdmin]);

	useEffect(() => {
		if (user) {
			handleFirstTimeLogin();
			handleResetSearch();
		}
	}, [handleFirstTimeLogin, handleResetSearch, location.pathname, user]);

	if (!user || isSubscriptionLoading || !isSubscriptionChecked) return null;

	return (
		<PrivateLayout>
			<PrivateLayout.Sidebar isSidebarOpen={isSidebarOpen}>
				<Sidebar />
			</PrivateLayout.Sidebar>
			<PrivateLayout.Content isSidebarOpen={isSidebarOpen}>
				<Suspense fallback={<LinearProgress />}>
					<Outlet />
				</Suspense>
			</PrivateLayout.Content>
			<PermissionModal
				title="Your Plan Has Expired"
				description="To continue enjoying all the features, please choose a plan to subscribe. If you’re not ready to upgrade, no worries—your data is securely stored for the next 30 Days."
				content={content}
				isOpen={isOpen}
				handleModalClose={handleModalClose}
				showCloseButton
			/>
			<ModalContainer isOpen={isFirstLoginModalOpen} title="First Time Login">
				<Box display="flex" flexDirection="column" alignItems="center" gap="16px" p="24px">
					<Typography variant="body1">
						Please set your name and change your password before accessing the app!
					</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							setIsFirstLoginModalOpen(false);
							navigate(RoutesEnum.SETTINGS);
						}}
						sx={{ mt: 2, width: '200px' }}
					>
						Go to Settings
					</Button>
				</Box>
			</ModalContainer>
		</PrivateLayout>
	);
};

export default PrivateRoute;
