import { useCallback, useEffect, useMemo, useState } from 'react';
import { Ad } from 'shared/models/ads';
import { Tag } from 'shared/models/tags';

interface UseSaveAdSubMenuProps {
  ad: Ad | undefined;
  tags: Tag[];
  searchTerm: string;
  setCheckedTagIds: React.Dispatch<React.SetStateAction<number[]>>;
}

export const useSaveAdSubMenu = ({
  ad,
  tags,
  searchTerm,
  setCheckedTagIds,
}: UseSaveAdSubMenuProps) => {
  const [expandedTags, setExpandedTags] = useState<{ [key: number]: boolean }>({});
  const [initialCheckedTagIds, setInitialCheckedTagIds] = useState<number[]>([]);
  const [checkedTagIds, setCheckedTagIdsInternal] = useState<number[]>([]);

  useEffect(() => {
    if (ad?.tags) {
      const initialTagIds = ad.tags.map((tag) => tag.id);
      setCheckedTagIdsInternal(initialTagIds);
      setCheckedTagIds(initialTagIds);
      setInitialCheckedTagIds(initialTagIds);
    } else if (ad?.user_ad_tags) {
      const initialTagIds = ad.user_ad_tags.map((user_ad_tag) => user_ad_tag.id);
      setCheckedTagIdsInternal(initialTagIds);
      setCheckedTagIds(initialTagIds);
      setInitialCheckedTagIds(initialTagIds);
    }
  }, [ad, setCheckedTagIds]);

  useEffect(() => {
    if (tags.length > 0) {
      setExpandedTags((prev) =>
        tags.reduce<{ [key: number]: boolean }>((acc, tag) => {
          acc[tag.id] = prev[tag.id] || false;
          return acc;
        }, {})
      );
    }
  }, [tags]);

  const filteredTags = useMemo(
    () =>
      searchTerm
        ? tags.filter((tag) => {
            const tagMatches = tag.name.toLowerCase().includes(searchTerm.toLowerCase());
            const subtagMatches = tag.subTags?.some((subTag) =>
              subTag.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            return tagMatches || subtagMatches;
          })
        : tags,
    [tags, searchTerm]
  );

  const handleTagChange = useCallback(
    (tagId: number, isChecked: boolean) => {
      setCheckedTagIdsInternal((prev) => {
        const updatedTags = isChecked ? [...prev, tagId] : prev.filter((id) => id !== tagId);
        setCheckedTagIds(updatedTags);
        return updatedTags;
      });
    },
    [setCheckedTagIds]
  );

  const hasTagsChanged = useCallback(() => {
    if (initialCheckedTagIds.length !== checkedTagIds.length) {
      return true;
    }
    const sortedInitialIds = [...initialCheckedTagIds].sort();
    const sortedCheckedIds = [...checkedTagIds].sort();
    return !sortedInitialIds.every((id, index) => id === sortedCheckedIds[index]);
  }, [initialCheckedTagIds, checkedTagIds]);

  const toggleExpandedTags = useCallback((tagId: number) => {
    setExpandedTags((prev) => ({
      ...prev,
      [tagId]: !prev[tagId],
    }));
  }, []);

  return {
    filteredTags,
    expandedTags,
    handleTagChange,
    hasTagsChanged,
    toggleExpandedTags,
    checkedTagIds
  };
};
